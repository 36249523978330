<template>
  <addEditDeviceView ref="addEditDeviceView" />
    <deviceQrCodeInfoView ref="deviceQrCodeInfoView" />

  <addFileView ref="addFileView" />
  <el-main>
    <div class="teacher">
      <div class="app-table">
        <div class="app-body">
          <div>
            <el-form :inline="true" class="demo-form-inline" v-model="form">
              <el-form-item label="设备名称">
                <el-input
                  placeholder="请输入设备名称"
                  v-model="form.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="设备地点">
                <el-input
                  placeholder="请输入设备地点"
                  v-model="form.address"
                ></el-input>
              </el-form-item>
              <el-form-item label="设备厂商">
                <el-input
                  placeholder="请输入设备厂商"
                  v-model="form.brand"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="onSubmit"
                  @keyup.enter="searchEnter"
                  >搜索</el-button
                >
                <el-button type="success" @click="add">添加</el-button>
                <!-- <el-button type="warning" @click="exportExcel">导出报表</el-button> -->
                <!-- <el-button type="danger" @click="importExcel">批量导入<el-icon class="el-icon--right"><Upload /></el-icon></el-button> -->
              </el-form-item>
            </el-form>
          </div>
          <el-table
            size="small"
            :data="list"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="id" label="id" />
            <el-table-column prop="name" label="设备名称" />
            <el-table-column prop="code" label="设备编码" />
            <el-table-column prop="type" label="设备类型">
              <template #default="scpd">
                <span v-for="(item, index) in filteredDeviceTypeList(scpd.row.type)" :key="index">
                  {{ item.devicetype }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="设备地点" />
            <el-table-column prop="brand" label="设备厂商" />
            <el-table-column prop="imgUrl" label="设备图片">
              <template #default="scpd">
                <el-image :src="'https://bx.hxlcyc.com/repair' + scpd.row.imgUrl"  style="width: 100px; height: 100px" fit="fill"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="brandMobile" label="厂商电话" />
            <el-table-column label="操作" width="240px">
              <template style="display: block" #default="scope">
                    <el-button
                  size="small"
                  type="warning"
                  @click="scanDevice(scope.row)"
                  >查看二维码
                </el-button>
                <el-button
                  size="small"
                  type="success"
                  @click="editDevice(scope.row)"
                  >编辑
                </el-button>
                <el-button
                  size="small"
                  type="danger"
                  @click="deleteDevice(scope.row.id)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
            <template v-slot:empty>
              <el-empty description="暂无数据"></el-empty>
            </template>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination
              :total="total"
              :page-size="limit"
              :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="changePageSize"
              @current-change="changeCurrentPage"
            >
            </el-pagination>
          </el-config-provider>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import addEditDeviceView from "@/components/addEditDevice.vue";
import addFileView from "@/components/addExcelFile.vue";
import deviceQrCodeInfoView from "@/components/deviceQrCodeInfo.vue";
import { ElConfigProvider, ElMessageBox, ElMessage } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { httpService } from "@/utils/httpService";
import { Picture as IconPicture, Upload } from "@element-plus/icons-vue";

export default {
  name: "DeviceListView",
  data() {
    return {
      type: 0,
      title: "",
      page: 0,
      pageSize: 10,
      total: 0,
      limit: 10,
      list: [],
      deviceTypeList:[],
      form: {
        name: "",
        code: "",
        address: "",
        brand: "",
        brandMobile: "",
        imgUrl:"",
      },
    };
  },
  components: {
    addEditDeviceView,
    addFileView,deviceQrCodeInfoView,
    IconPicture,
    ElConfigProvider,
    ElMessageBox,
    ElMessage,
    Upload,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  mounted() {
    this.getDeviceList();
    this.getDeviceTypeList();
  },
  watch: {
    $route() {
      this.getDeviceList();
      this.getDeviceTypeList();
    },
  },
  methods: {
    getDeviceTypeList() {
      httpService("/api/deviceType/queryAll", "", "get").then((data) => {
        this.deviceTypeList = data;
      });
    },
    filteredDeviceTypeList(type) {
      return this.deviceTypeList.filter(item => item.id === type);
    },
    searchEnter: function (e) {
      // 使用 which 和 keyCode 属性来解决兼容问题
      var keyCode = window.event ? e.keyCode : e.which;
      var val = e.target.value;
      if (keyCode == 13 && val) {
        this.getDeviceList();
      }
    },
    add() {
      this.$refs.addEditDeviceView.title = "添加设备信息";
      this.$refs.addEditDeviceView.personnelType = this.type;
      this.$refs.addEditDeviceView.deviceTypeList = this.deviceTypeList;
      this.$refs.addEditDeviceView.dialogFormVisible = true;
    },
    editDevice(obj) {
      this.$refs.addEditDeviceView.title = "修改信息";
      this.$refs.addEditDeviceView.deviceTypeList = this.deviceTypeList;
      this.$refs.addEditDeviceView.getDeviceById(obj.id);
      this.$refs.addEditDeviceView.dialogFormVisible = true;
    },
    scanDevice(obj) {
      this.$refs.deviceQrCodeInfoView.title = "设备二维码";
      this.$refs.deviceQrCodeInfoView.getDeviceById(obj.id);
      this.$refs.deviceQrCodeInfoView.dialogFormVisible = true;
    },
    deleteDevice(id) {
      ElMessageBox.confirm("确定要删除本条记录吗？", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          httpService("/api/device/deleteById", "id=" + id, "post").then(
            (data) => {
              if (data.code == 200) {
                ElMessage.success("删除成功");
                this.getDeviceList();
              } else {
                ElMessage.error("删除失败，请重试！");
              }
            }
          );
        })
        .catch(() => {});
    },
    reloadData() {
      this.page = 1;
      this.getDeviceList();
    },
    importExcel() {
      this.$refs.addFileView.dialogFormVisible = true;
      this.$refs.addFileView.title = "批量导入设备信息";
      this.$refs.addFileView.uploadApiUrl = "/api/device/importExcel";
    },
    exportExcel() {
      httpService(
        "/api/device/exportExcel",
        {
          name: this.form.name,
          code: this.form.code,
          address: this.form.address,
          brand: this.form.brand,
          brandMobile: this.form.brandMobile,
        },
        "get"
      ).then((data) => {
        if (data.code == 200) {
          window.open(data.msg, "_blank");
        }
      });
    },
    onSubmit() {
      this.getDeviceList();
    },
    // 每页记录数改变，size：回调参数，表示当前选中的“每页条数”
    changePageSize(size) {
      this.pageSize = size;
      this.limit = size;
      this.getDeviceList();
    },

    // 改变页码，page：回调参数，表示当前选中的“页码”
    changeCurrentPage(page) {
      this.page = page;
      this.getDeviceList();
    },
    tableRowClassName({ row, rowIndex }) {
      return "";
    },
    getDeviceList() {
      httpService(
        "/api/device/list/" + this.page + "/" + this.pageSize,
        {
          name: this.form.name,
          code: this.form.code,
          address: this.form.address,
          brand: this.form.brand,
          brandMobile: this.form.brandMobile,
        },
        "get"
      ).then((data) => {
        this.list = data.list;
        this.total = data.total;
        this.pageCount = data.pages;
      });
    },
  },
};
</script>
<style scoped>
.el-main {
  padding: 0px;
}

.teacher-content {
  width: 100%;
}

.app-table {
  background: #eff3f5;
  padding: 10px;
}

.app-search {
  padding-top: 20px;
  border-top: 1px solid #dedede;
  margin-top: 20px;
}

.app-separator {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
}

.app-body {
  background: white;
  padding: 20px 20px 10px 20px;
  margin-bottom: 10px;
}

.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-lighter);
}

.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-lighter);
}

.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>

