<template>
  <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
    <el-form :model="addDeviceForm" ref="addDeviceRef" :rules="addDevicerules">
      <div
        ref="qrCode"
        style="
          border: 10px solid #3070f0;
          padding: 20px 20px 30px 20px;
          width: 380px;
          margin: 0 auto;
        "
      >
        <div style="font-size: 20px">No.{{ addDeviceForm.id }}</div>
        <div
          style="
            background: #444444;
            margin-top: 10px;
            margin-bottom: 20px;
            height: 3px;
          "
        ></div>
        <div class="qr-view">
          <div style="flex: 2">
            <div style="font-size: 18px; padding-bottom: 10px">
              设备名称: {{ addDeviceForm.name }}
            </div>
            <div style="font-size: 18px; padding-bottom: 10px">
              设备编码:{{ addDeviceForm.code }}
            </div>
            <div style="font-size: 18px; padding-bottom: 10px">
              设备厂商:{{ addDeviceForm.brand }}
            </div>
            <div style="font-size: 18px; padding-bottom: 10px">
              厂商电话:{{ addDeviceForm.brandMobile }}
            </div>
          </div>
          <div style="flex: 1">
            <qrcode-vue :value="qCode" :size="size" level="H" />
          </div>
        </div>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()">关闭</el-button>
        <el-button type="primary" @click="save()">下载二维码</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref } from "vue";
import { httpService } from "@/utils/httpService";
import { ElMessage, ElForm } from "element-plus";
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";
export default {
  name: "qrDevice",
  components: { ElForm, ElMessage, QrcodeVue, html2canvas },
  data() {
    return {
      dialogFormVisible: ref(false),
      formLabelWidth: "120px",
      title: "",
      size: "140",
      qCode:"wl",
      personnelType: "",
      addDeviceForm: {
        name: "",
        code: "",
        type: "",
        address: "",
        brand: "",
        brandMobile: "",
      },
      // 校验规则
      addDevicerules: {},
    };
  },
  methods: {
    closeDialog() {
      this.clear();
    },
    cancel() {
      this.dialogFormVisible = false;
      this.clear();
    },
    getDeviceById(id) {
      httpService("/api/device/queryById", { id: id }, "get").then((data) => {
        this.addDeviceForm = data;
        this.qCode = "wj:"+data.code
      });
    },
    clear() {
      this.dialogFormVisible = false;
      //清空form
      this.addDeviceForm.id = "";
      this.addDeviceForm.name = "";
      this.addDeviceForm.code = "";
      this.addDeviceForm.type = "";
      this.addDeviceForm.address = "";
      this.addDeviceForm.brand = "";
      this.addDeviceForm.brandMobile = "";
    },
    save() {
      console.log("下载二维码");
      this.saveImage(
        "qrCode",
        this.addDeviceForm.name + "No." + this.addDeviceForm.id
      );
    },
    /*保存图片的方法（即按钮点击触发的方法）

       第一个参数为需要保存的div的id名

       第二个参数为保存图片的名称 */

    saveImage(divText, imgText) {
      let canvasID = this.$refs[divText];

      let that = this;

      let a = document.createElement("a");

      html2canvas(canvasID).then((canvas) => {
        let dom = document.body.appendChild(canvas);

        dom.style.display = "none";

        a.style.display = "none";

        document.body.removeChild(dom);

        let blob = that.dataURLToBlob(dom.toDataURL("image/png"));

        a.setAttribute("href", URL.createObjectURL(blob));

        //这块是保存图片操作 可以设置保存的图片的信息

        a.setAttribute("download", imgText + ".png");

        document.body.appendChild(a);

        a.click();

        URL.revokeObjectURL(blob);

        document.body.removeChild(a);
      });
    },
    //图片转换格式的方法 直接使用就好 不需要知道为什么

    dataURLToBlob(dataurl) {
      let arr = dataurl.split(",");

      let mime = arr[0].match(/:(.*?);/)[1];

      let bstr = atob(arr[1]);

      let n = bstr.length;

      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    },
  },
};
</script>
<style>
.el-select {
  width: 100%;
}
.qr-view {
  display: flex;
}
</style>